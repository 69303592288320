import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, Link, graphql } from "gatsby"
// import Img from "gatsby-image"

export default props => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 1000
        ) {
          edges {
            node {
              frontmatter {
                author
                title
                date(formatString: "MMMM DD, YYYY")
                excerpt
                path
                cover {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Archive
        blogPosts={data?.allMarkdownRemark?.edges?.map(
          x => x.node?.frontmatter
        )}
        {...props}
      />
    )}
  />
)

const Archive = ({ blogPosts }) => {
  return (
    <>
      {blogPosts.map(x => (
        <Link
          key={x.path}
          to={x.path}
          style={{
            textDecoration: `none`,
            color: `black`,
          }}
        >
          <article
            key={x.path}
            style={{
              marginBottom: `2rem`,
              borderBottom: `0.2rem solid #303430`,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <section
              style={{
                width: `100%`,
                padding: `0.5rem`,
              }}
            >
              <h1
                style={{
                  color: `white`,
                  margin: `0`,
                }}
              >
                <span
                  style={{
                    color: `white`,
                    background: `#303430`,
                    padding: `0 0.5rem`,
                  }}
                >
                  {x.title}
                </span>
              </h1>
              <p
                style={{
                  fontSize: "0.75rem",
                }}
              >
                Published{" "}
                <time style={{ fontVariant: "small-caps" }}>{x.date}</time>
              </p>
              <p>{x.excerpt}</p>
            </section>

            {x.cover && (
              <section
                style={{
                  marginBottom: `1rem`,
                  height: `400px`,
                  width: "100%",
                }}
              >
                <img
                  alt={x.title}
                  src={x.cover.childImageSharp.fluid.src}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    objectPosition: `center center`,
                    overflow: "hidden",
                  }}
                />
              </section>
            )}
          </article>
        </Link>
      ))}
    </>
  )
}

Archive.propTypes = {
  blogPosts: PropTypes.shape({
    author: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string,
    path: PropTypes.string.isRequired,
    // cover: PropTypes.string,
  }).isRequired,
}
